<template>
  <component
    class="cy-app-logo"
    :style="{
      height: '40px',
      width: $showFeature.whiteLabel ? '40px' : 'auto',
    }"
    data-cy="app-logo"
    alt="App logo"
    :is="logo"/>
</template>

<script>
import { mapGetters } from 'vuex'
import LegacyLogo from 'static/images/app-logo.svg'

export default {
  name: 'CyAppLogo',
  components: {
    LegacyLogo,
  },
  computed: {
    ...mapGetters([
      'appearance',
    ]),
    appearanceLogo () {
      if (!this.appearance) return null

      const src = this.appearance.logo
      return {
        render: (h) => h('img', { attrs: { src } }),
      }
    },
    logo () {
      return this.$showFeature.whiteLabel
        ? this.appearanceLogo
        : LegacyLogo
    },
  },
}
</script>

<style lang="scss" scoped>
.cy-app-logo {
  object-fit: contain;
  min-width: 40px;
}
</style>
